import { observable, action } from 'mobx'

export default class Ui {
  @observable windowHeight = 0
  @observable windowWidth = 0
  @observable mouseLeft = 0
  @observable mouseTop = 0
  @observable headerHeight = 0
  @observable activeMenu: '' | 'nav' | 'member' = ''
  @observable showPreviewbar = true
  @observable cursorContent = ''
  @observable memberActive = false

  @action.bound
  setWindowSize({ width, height }: { width: number; height: number }) {
    this.windowWidth = width
    this.windowHeight = height
  }

  @action.bound
  setMousePosition({ left, top }: { left: number; top: number }) {
    this.mouseLeft = left
    this.mouseTop = top
  }

  @action.bound
  setCursorContent(cursorContent: string) {
    if (cursorContent !== this.cursorContent) {
      this.cursorContent = cursorContent
    }
  }

  @action.bound
  setHeaderHeight(height: number) {
    this.headerHeight = height
  }

  @action.bound
  setActiveMenu(selected: string) {
    this.activeMenu = selected
  }

  @action.bound
  setMemberActive(active: boolean) {
    this.memberActive = active
  }

  @action.bound
  togglePreviewbar() {
    this.showPreviewbar = !this.showPreviewbar
  }
}
