import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

// import seo from 'src/json/seo.json'

import SbEditable from 'storyblok-react'
import { reaction } from 'mobx'

import Header from 'src/storyblok/GlobalModules/Header/Header'
import Footer from 'src/storyblok/GlobalModules/Footer/Footer'
import SEO from 'src/components/SEO/SEO'
import useStore from 'src/stores/useStore'
import { getSeo } from 'src/helpers/getSeo'

import { Props } from './types'

export default ({ children, pageContext }: Props) => {
  const { ui } = useStore()
  const { globalData, story }: any = pageContext ? pageContext : {}
  const { header, footer, seo }: any = globalData ? globalData : {}

  const seo_data: any = seo ? seo.content : {}

  const { seo_description, seo_title, seo_image } = getSeo(seo_data)

  const onResize = () => {
    ui?.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  useEffect(() => {
    const disposer = reaction(
      () => ui.activeMenu,
      () => {
        if (ui.activeMenu) {
          document.body.classList.add('prevent-overflow')
        } else {
          document.body.classList.remove('prevent-overflow')
        }
      }
    )
    ui?.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      disposer()
    }
  }, [])

  return (
    <React.Fragment>
      {header && (
        <SbEditable content={header.content}>
          <Header blok={header.content} />
        </SbEditable>
      )}
      {(seo_description || seo_title || seo_image) && (
        <React.Fragment>
          {seo_title && (
            <Helmet
              titleTemplate={`%s — ${seo_title}`}
              defaultTitle={seo_title}
            />
          )}
          <SEO
            title={story?.name && story?.slug !== 'home' ? story.name : ''}
            metaDescription={seo_description}
            image={seo_image}
            metaTitle={seo_title}
          />
        </React.Fragment>
      )}
      {children}
      {footer && (
        <SbEditable content={footer.content}>
          <Footer blok={footer.content} />
        </SbEditable>
      )}
    </React.Fragment>
  )
}
