import React from 'react'
import Helmet from 'react-helmet'
import parseWithOptions from 'date-fns/fp/parseWithOptions'

import fontHeadline from 'src/styles/fonts/FuturaNowHeadline-Rg/font.woff2'
import fontRegular from 'src/styles/fonts/FuturaNowText-Rg/font.woff2'
import fontMedium from 'src/styles/fonts/FuturaNowText-Md/font.woff2'
import fontLight from 'src/styles/fonts/FuturaNowText-Lt/font.woff2'

import { Props } from './types'

const SITE_URL = process.env.GATSBY_SITE_URL

const SEO = (props: Props) => {
  const {
    metaDescription,
    title,
    metaTitle,
    type = 'website',
    bodyClass,
    path = '',
  } = props
  let { image } = props

  if (image) {
    if (image.startsWith('//')) {
      image = 'https:' + image
    }
  }

  const helmetProps = {
    bodyAttributes: {
      class: bodyClass || '',
    },
    htmlAttributes: {
      lang: 'sv',
    },
  }

  const url = path ? `${SITE_URL}/${path === 'home' ? '' : path}` : SITE_URL

  return (
    <Helmet defer={false} {...helmetProps}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link rel="preconnect" href="https://img2.storyblok.com" />
      <link rel="preload" as="font" type="font/woff2" href={fontHeadline} />
      <link rel="preload" as="font" type="font/woff2" href={fontRegular} />
      <link rel="preload" as="font" type="font/woff2" href={fontMedium} />
      <link rel="preload" as="font" type="font/woff2" href={fontLight} />

      {title && <title>{title}</title>}
      {(title || metaTitle) && (
        <meta name="title" property="og:title" content={title || metaTitle} />
      )}
      {(title || metaTitle) && (
        <meta
          name="title"
          property="twitter:title"
          content={title || metaTitle}
        />
      )}
      {(title || metaTitle) && (
        <meta property="og:image:alt" content={title || metaTitle} />
      )}
      {(title || metaTitle) && (
        <meta property="twitter:image:alt" content={title || metaTitle} />
      )}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && (
        <meta
          name="description"
          property="og:description"
          content={metaDescription}
        />
      )}
      {image && <meta name="image" property="og:image" content={image} />}
      {image && (
        <meta name="image" property="twitter:image:src" content={image} />
      )}
      {type && <meta name="type" property="og:type" content={type} />}
      {type && <meta name="type" property="twitter:type" content={type} />}

      {/* {url && <meta name="url" property="og:url" content={url} />} */}
      {/* 
      {type && <meta property="twitter:url" content={url} />} */}
      <html lang="en" />
    </Helmet>
  )
}

export default SEO
