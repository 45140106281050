import React from 'react'
import { Box } from 'reflexbox'

const Column = (props: any) => <Box {...props} />

Column.defaultProps = {
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  paddingX: ['6px', null, null, 3],
  // maxWidth: '100%',
}

export default Column
