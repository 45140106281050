import * as React from 'react'
import classnames from 'classnames'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'

import styles from './Markdown.module.scss'

// import { getIntlUrl } from 'src/utils/intl'

type Props = {
  className?: any
  noParagraphs?: boolean
  source: any
  escapeHtml?: boolean
  themeLight?: boolean
}

type MarkdownProps = {
  className: any
  disallowedTypes?: any
  noParagraphs?: boolean
  plugins: any
  source: any
  linkTarget: any
  unwrapDisallowed?: boolean
  escapeHtml?: boolean
}

const Markdown = ({
  className = '',
  noParagraphs = false,
  source,
  escapeHtml = false,
  themeLight = false,
}: Props): JSX.Element => {
  const linkTarget = (url = '') => {
    let returnTarget
    const localUrl = url?.split('?')?.length > 0 ? url.split('?')[0] : ''

    const isNew = localUrl.substr(0, 4) === 'http'
    const isSpotifyUri = localUrl.substr(0, 7) === 'spotify'
    const isDownload = localUrl.substr(0, 9) === 'data:text'
    const isMail = localUrl.split(':')[0] === 'mailto'
    const isPhone = localUrl.split(':')[0] === 'tel'
    const isPdf = localUrl.substr(-4, 4) === '.pdf'

    const isExternal =
      isNew || isMail || isPhone || isDownload || isSpotifyUri || isPdf

    if (isExternal) {
      returnTarget = '_blank'
    }

    return returnTarget
  }

  const LinkRenderer = (props: any) => {
    const { children, node } = props
    const { url } = node

    const target = linkTarget(url)

    return (
      <a
        href={url}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : ''}
      >
        {children}
      </a>
    )
  }

  const plugins = [remarkBreaks]

  const markdownProps: MarkdownProps = {
    className,
    linkTarget,
    plugins,
    source,
    escapeHtml,
  }

  if (noParagraphs) {
    markdownProps.disallowedTypes = ['paragraph']
    markdownProps.unwrapDisallowed = true
  }

  return (
    <div
      className={classnames(
        styles.markdown,
        themeLight ? styles.themeLight : ''
      )}
    >
      <ReactMarkdown renderers={{ link: LinkRenderer }} {...markdownProps} />
    </div>
  )
}

export default Markdown
