import React from 'react'
import { Box, Flex } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Markdown from 'src/components/Markdown/Markdown'
import Container from 'src/components/UI/Grid/Container'
import Row from 'src/components/UI/Grid/Row'
import Column from 'src/components/UI/Grid/Column'
import { SbMedia, SbTextarea } from 'src/storyblok/types/storyblokTypes'
import Image from 'src/components/UI/Image/Image'
import Symbol from 'src/components/UI/Elements/Symbol'

import styles from './Footer.module.scss'

type Props = {
  blok: {
    main_partner: SbMedia
    special_partners: SbMedia[]
    links: StoryblokLink[]
    privacy_policy_link: StoryblokLink[]
    contact_info: SbTextarea[]
    address: string
    social_links: StoryblokLink[]
  }
}

const Footer = (props: Props) => {
  const { blok } = props

  const {
    main_partner,
    special_partners,
    links,
    privacy_policy_link,
    contact_info,
    address,
    social_links,
  }: any = blok ? blok : {}
  const privacy_link =
    privacy_policy_link && privacy_policy_link[0]
      ? privacy_policy_link[0]
      : null

  return (
    <Container
      paddingY={[17]}
      className={styles.wrapper}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Row flexDirection="column" alignItems="center">
        <Column
          marginBottom={[5, null, null, 20]}
          width={[1, null, null, 10 / 12]}
        >
          <Row>
            <Column className="hideMobile" width={[1, null, null, 3 / 10]}>
              <Box paddingTop={[0, null, null, 7]}>
                <Symbol width={57} />
              </Box>
            </Column>
            <Column
              alignItems="flex-start"
              flexDirection="column"
              width={[1, null, null, 7 / 10]}
            >
              {main_partner && main_partner.length > 0 && (
                <Box marginBottom={[5, null, null, 10]}>
                  <Box
                    marginBottom={[6, null, null, 6]}
                    className={styles.label}
                  >
                    Official main partner
                  </Box>
                  <Flex
                    width={1}
                    justifyContent={['center', null, null, 'flex-start']}
                  >
                    {main_partner.map((partner: any, index: number) => {
                      const { image, link } = partner
                      return image?.filename ? (
                        <Box
                          className={styles.partnerWrapper}
                          key={`${partner._uid}${index}`}
                          marginRight={[0, null, null, 14]}
                          marginBottom={[8, null, null, 4]}
                        >
                          {link?.slug ? (
                            <LinkComponent to={link.slug}>
                              <Image
                                className={styles.partner}
                                src={image.filename}
                                alt={image.title}
                                sizes={'20vw'}
                              />
                            </LinkComponent>
                          ) : (
                            <Image
                              className={styles.partner}
                              src={image.filename}
                              alt={image.title}
                              sizes={'20vw'}
                            />
                          )}
                        </Box>
                      ) : null
                    })}
                  </Flex>
                </Box>
              )}

              {special_partners && special_partners.length > 0 && (
                <Box marginBottom={[5, null, null, 10]}>
                  <Box
                    marginBottom={[6, null, null, 6]}
                    className={styles.label}
                  >
                    Special thanks to
                  </Box>
                  <Flex
                    alignItems="center"
                    justifyContent={['center', null, null, 'flex-start']}
                    flexWrap="wrap"
                    width={1}
                    flexDirection={['column', null, null, 'row']}
                  >
                    {special_partners.map((partner: any, index: number) => {
                      const { image, link } = partner
                      return image?.filename ? (
                        <Box
                          className={styles.partnerWrapper}
                          key={partner._uid}
                          marginRight={[0, null, null, 14]}
                          marginBottom={[8, null, null, 4]}
                        >
                          {link?.slug ? (
                            <LinkComponent to={link.slug}>
                              <Image
                                className={styles.partner}
                                src={image.filename}
                                alt={image.title}
                                sizes={'20vw'}
                              />
                            </LinkComponent>
                          ) : (
                            <Image
                              className={styles.partner}
                              src={image.filename}
                              alt={image.title}
                              sizes={'20vw'}
                            />
                          )}
                        </Box>
                      ) : null
                    })}
                  </Flex>
                </Box>
              )}
            </Column>
          </Row>
        </Column>

        <Column width={[1, null, null, 10 / 12]}>
          <Row>
            <Column
              width={[1, null, null, 3 / 10]}
              marginBottom={[9, null, null, 0]}
            >
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Flex
                  flexDirection={['row', null, null, 'column']}
                  flexWrap="wrap"
                >
                  {links &&
                    links.length > 0 &&
                    links.map(({ title, slug, _uid }: StoryblokLink) => (
                      <Box
                        width={[1 / 2, null, null, 1]}
                        mb={[5, null, null, 3]}
                        key={_uid}
                      >
                        <LinkComponent className={styles.link} to={slug}>
                          {title}
                        </LinkComponent>
                      </Box>
                    ))}
                </Flex>
                <Flex
                  marginTop="auto"
                  className={['hideMobile', styles.copyright].join(' ')}
                >
                  <Box as="p">© POLAR MUSIC PRIZE 2020 • </Box>
                  {privacy_link && (
                    <LinkComponent
                      className={styles.link}
                      to={privacy_link.slug}
                    >
                      {privacy_link.title}
                    </LinkComponent>
                  )}
                </Flex>
              </Flex>
            </Column>
            {contact_info && (
              <Column
                marginBottom={[14, null, null, 0]}
                flexDirection="column"
                width={[1, null, null, 3 / 10]}
              >
                <Box marginBottom={6} as="p" className={styles.label}>
                  Contact
                </Box>
                {contact_info.map((blok: any, index: number) => (
                  <Box
                    key={blok._uid}
                    marginBottom={index < contact_info.length - 1 ? 5 : 0}
                  >
                    <Markdown source={blok.text} />
                  </Box>
                ))}
              </Column>
            )}
            {address && (
              <Column
                marginBottom={[14, null, null, 0]}
                flexDirection={'column'}
                width={[1, null, null, 3 / 10]}
              >
                <Box marginBottom={6} as="p" className={styles.label}>
                  Postal address
                </Box>
                <Markdown source={address} />
              </Column>
            )}
            <Column width={[1, null, null, 1 / 10]}>
              <Flex
                flexDirection={['row', null, null, 'column']}
                flexWrap={['wrap', null, null, 'no-wrap']}
              >
                {social_links?.length > 0 &&
                  social_links.map(({ title, slug, _uid }: StoryblokLink) => (
                    <Box mb={3} width={[1 / 2, null, null, 1]} key={_uid}>
                      <LinkComponent className={styles.link} to={slug}>
                        {title}
                      </LinkComponent>
                    </Box>
                  ))}
              </Flex>
            </Column>
            <Column
              width={1}
              marginTop={14}
              className={['hideDesktop', styles.copyright].join(' ')}
            >
              <Box as="p">
                © POLAR MUSIC PRIZE 2020 •{' '}
                {privacy_link && (
                  <LinkComponent className={styles.link} to={privacy_link.slug}>
                    {privacy_link.title}
                  </LinkComponent>
                )}
              </Box>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  )
}

export default Footer
