import React from 'react'
import Lottie from 'react-lottie'
import { Box } from 'reflexbox'
import * as animationData from './lottie.json'
import styles from './PolarCircle.module.scss'
import { Props } from './types'

const PolarCircle = ({ className, size = 800 }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const classNames = [styles.circle, className].join(' ')
  return (
    <Box className={classNames}>
      <Lottie
        isClickToPauseDisabled={true}
        speed={1}
        options={defaultOptions}
        height={size}
        width={size}
      />
    </Box>
  )
}

export default PolarCircle

// const Circle = ({ color, variant }: Props) => (
//   // <svg
//   //   fill="none"
//   //   xmlns="http://www.w3.org/2000/svg"
//   //   viewBox="0 0 1049 1073"
//   //   width="1049"
//   //   height="1073"
//   //   className={styles.circle}
//   // >
//   //   <g
//   //     clipPath="url(#clip0)"
//   //     stroke={color}
//   //     strokeWidth="1.209"
//   //     strokeMiterlimit="10"
//   //   >
//   //     <path d="M537.558 147.186c287.318 8.416 397.949 179.834 415.208 420.565 17.694 246.911 34.363 514.009-340.523 446.059C237.357 945.866 9.638 830.593 46.794 487.88 80.381 177.961 268.71 139.33 537.558 147.186z" />
//   //     <path d="M559.41 127.001c256.65-3.509 469.72 313.646 452.99 534.068-16.735 220.421-90.924 302.156-431.106 307.87-174.39 2.919-528.602-162.724-504.98-469.662 25.112-326.192 279.434-369.481 483.096-372.276z" />
//   //     <path d="M185.425 772.025C37.233 562.348 177.944 207.09 369.065 96.103c191.121-110.988 281.11-81.269 497.717 181.231 145.458 176.263 179.918 556.954-101.133 682.32-281.048 125.366-462.64-21.241-580.224-187.629z" />
//   //     <path d="M291.068 933.956C53.93 792.94 36.843 382.861 175.988 190.469 315.133-1.923 428.99-35.066 743.903 150.904c161.407 95.312 368.697 516.927 198.882 713.73-215.994 250.346-463.541 181.206-651.717 69.322z" />
//   //     <path d="M393.862 982.57c-265.22-75.664-420.667-521.61-311.88-732.785 73.016-141.781 197.675-239.608 549.51-140.359C811.841 160.289 1156.98 454.434 1006 748.73 855.035 1043 604.292 1042.6 393.862 982.57z" />
//   //   </g>
//   //   <defs>
//   //     <clipPath id="clip0">
//   //       <path fill="#fff" d="M0 0h1048.33v1072.51H0z" />
//   //     </clipPath>
//   //   </defs>
//   // </svg>
// )
