import React from 'react'
import { Box } from 'reflexbox'

const Container = (props: any) => <Box {...props} />

Container.defaultProps = {
  paddingX: [6, null, null, 6],
  width: 1,
}

export default Container
