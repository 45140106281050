import React from 'react'
import { Box, Flex } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import useStore from 'src/stores/useStore'
import Container from 'src/components/UI/Grid/Container'
import Logotype from 'src/components/UI/Logotype/Logotype'
import PolarCircle from 'src/components/UI/Elements/PolarCircle/PolarCircle'
import { CloseIcon } from 'src/components/UI/Elements/Icon'

import styles from './MobileNav.module.scss'
import { Props } from './types'

const MobileNav = ({ links, onClick }: Props) => {
  const { ui } = useStore()
  return (
    <Container
      className={
        ui.activeMenu === 'nav'
          ? [styles.wrapper, styles.active].join(' ')
          : styles.wrapper
      }
      justifyContent="flex-start"
      flexDirection="column"
    >
      <Flex flexDirection="column" width={1} marginTop={18}>
        {links?.length > 0 &&
          links.map((link: any, index: number) => {
            return (
              <Box
                marginBottom={index + 1 === links.length ? 0 : 8}
                style={{ zIndex: 1 }}
                key={link._uid}
                className={styles.link}
              >
                <LinkComponent
                  to={link.slug}
                  onClick={() => {
                    ui.setActiveMenu('')
                    if (onClick) {
                      onClick(-1)
                      // setTimeout(() => {
                      // }, 50)
                    }
                  }}
                >
                  {link.title}
                </LinkComponent>
              </Box>
            )
          })}
      </Flex>
      <PolarCircle
        size={500}
        position="bottom-right"
        className={styles.circle}
      />
    </Container>
  )
}

export default MobileNav
